<template>
  <div class="customer-list-go-container">
    <el-tabs type="border-card">
      <el-tab-pane v-for="(item, index) in tableData" :key="item.id" :label="'出行人' + (index + 1)">
        <el-form label-width="350px">
          <el-form-item label="出行人姓名">
            <span>{{ item.chinese_name }}</span>
          </el-form-item>
          <!-- <el-form-item label="出行人英文姓">
            <span>{{ item.eng_first_name }}</span>
          </el-form-item>
          <el-form-item label="出行人英文名">
            <span>{{ item.eng_second_name }}</span>
          </el-form-item>
          <el-form-item label="出行人证件类型">
            <span>
              {{
              item.doc_type == 1 ? "身份证" : item.doc_type == 2 ? "护照" : item.doc_type == 3 ? "港澳台通行证" : "其他"
              }}
            </span>
          </el-form-item>
          <el-form-item label="出行人证件号">
            <span>{{ item.doc_nums }}</span>
          </el-form-item>
          <el-form-item label="出行人证件有效期至">
            <span>{{ item.doc_validity?item.doc_validity:'终身有效' }}</span>
          </el-form-item>
          <el-form-item label="出行人国籍">
            <span>{{ item.nationality === 1 ? '中国' : '其他' }}</span>
          </el-form-item> -->
          <el-form-item label="出行人手机号">
            <span>{{ item.phone }}</span>
          </el-form-item>
          <!-- <el-form-item label="出行人类型">
            <span>{{ item.identity_type === 1 ? "成人" : `儿童(${item.child_age}岁)` }}</span>
          </el-form-item> -->
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";

export default {
  name: "customerListGo",
  props: ["member-id"],
  data () {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        member_id: this.memberId,
      },
      tableData: [
        {
          id: 1,
          chinese_name: "张一丰1",
          eng_first_name: "ZHANG",
          eng_second_name: "YiFeng",
          doc_type: 1,
          doc_nums: "111201199012121236",
          end_time: "2025-12-31",
          nationality: "中国",
          mem_phone: "13111111111",
          identity_type: 0,
          child_age: 18,
        },
        {
          id: 2,
          chinese_name: "张一丰2",
          eng_first_name: "ZHANG",
          eng_second_name: "YiFeng",
          doc_type: 2,
          doc_nums: "111201199012121236",
          end_time: "2025-12-31",
          nationality: "中国",
          mem_phone: "13111111111",
          identity_type: 1,
          child_age: 18,
        },
        {
          id: 3,
          chinese_name: "张一丰3",
          eng_first_name: "ZHANG",
          eng_second_name: "YiFeng",
          doc_type: 3,
          doc_nums: "111201199012121236",
          end_time: "2025-12-31",
          nationality: "中国",
          mem_phone: "13111111111",
          identity_type: 0,
          child_age: 18,
        },
        {
          id: 4,
          chinese_name: "张一丰4",
          eng_first_name: "ZHANG",
          eng_second_name: "YiFeng",
          doc_type: 4,
          doc_nums: "111201199012121236",
          end_time: "2025-12-31",
          nationality: "中国",
          mem_phone: "13111111111",
          identity_type: 1,
          child_age: 18,
        },
      ],
    };
  },
  mounted () {
    this.GET_Travelerlist();
  },
  watch: {
    memberId () {
      this.params.member_id = this.memberId;
      this.GET_Travelerlist();
    },
  },
  methods: {
    /** 获取出行人列表 */
    GET_Travelerlist () {
      this.loading = true;
      // getEmergencierlist
      API_Member.getTravelerlist(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.customer-list-go-container {
  border-radius: 0;
}
</style>
