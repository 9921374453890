<template>
  <div class="container">
    <div class="contant">
      <el-row>
        <el-col :span="24">
          <el-tabs type="border-card">
            <el-tab-pane label="编辑资料">
              <el-form :model="editForm" :rules="editRules" ref="editForm" label-width="350px">
                <el-form-item label="客户名称" prop="nickname">
                  <el-input v-model="editForm.nickname" :maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="客户手机号" prop="mobile">
                  <el-input v-model="editForm.mobile" :maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="editForm.email" :maxlength="50"></el-input>
                </el-form-item>
                <el-form-item label="客户性别" class="form-item-sex">
                  <el-radio v-model="editForm.sex" :label="1">男</el-radio>
                  <el-radio v-model="editForm.sex" :label="2">女</el-radio>
                </el-form-item>

                <el-form-item label="生日">
                  <el-date-picker
                    v-model="editForm.birthday"
                    type="date"
                    :editable="false"
                    value-format="timestamp"
                    placeholder="选择生日"
                    :picker-options="{disabledDate: MixinDisabledDate}"
                  ></el-date-picker>
                </el-form-item>
                <!-- 城市 -->
                <el-form-item label="城市" class="form-item-region">
                  <en-region-picker
                    :api="MixinRegionApi"
                    :default="defaultRegion"
                    @changed="(object) => { editForm.region = object.last_id }"
                  />
                </el-form-item>
                <el-form-item label="账户启用状态" prop="disabled">
                  <el-switch v-model="editForm.disabled"></el-switch>
                </el-form-item>

                <el-form-item label="客户信息备注">
                  <el-input type="textarea" v-model="editForm.remark" :maxlength="200"></el-input>
                </el-form-item>
              </el-form>
              <el-button type="primary" @click="submitEditForm('editForm')" class="save">保存修改</el-button>
            </el-tab-pane>
            <el-tab-pane label="TA的订单">
              <customer-list-order :member-id="member_id"/>
            </el-tab-pane>
            <el-tab-pane label="出行人信息">
              <customer-list-go :member-id="member_id"/>
            </el-tab-pane>
            <el-tab-pane label="联系人信息">
              <customer-list-contact :member-id="member_id"/>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
import {RegExp} from "@/libs/common/utils";
import customerListGo from "@/components/CustomerListGo"; // 出行人信息
import customerListContact from "@/components/CustomerListContact"; // 联系人信息
import customerListOrder from "@/components/CustomerListOrder"; // TA的订单
export default {
  name: "customerEdit",
  components: {
    customerListGo,
    customerListContact,
    customerListOrder,
  },

  data() {
    return {
      member_id: this.$route.params.id,
      // 编辑会员 表单
      editForm: {},
      // 编辑会员 表单规则
      editRules: {
        nickname: [
          this.MixinRequired("请输入客户名称！"),
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.userName.test(value)) {
                callback(new Error("只支持汉字、字母、数字、“-”、“_”的组合！"));
              } else {
                callback();
              }
            },
          },
        ],
        mobile: [
          this.MixinRequired("请输入客户手机号！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机格式有误！"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      // 地区id数组
      defaultRegion: null,
    };
  },
  mounted() {
    this.GET_MemberDetail();
    this.member_id = this.$route.params.id;
  },
  watch: {
    member_id: function () {
      this.editForm = {};
      this.GET_MemberDetail();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.member_id = to.params.id;
    this.GET_MemberDetail();
    next();
  },
  activated() {
    this.member_id = this.$route.params.id;
    // type===member-examine就是从审核详情跳的
  },
  methods: {
    /** 提交修改表单 */
    submitEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.editForm);
          params.birthday /= 1000;
          params.nickname = escape(params.nickname);
          API_Member.editMember(params.member_id, params).then((response) => {
            this.$message.success("修改成功！");
          });
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 获取会员详情 */
    GET_MemberDetail() {
      API_Member.getMemberDetail(this.member_id).then((response) => {
        response.birthday *= 1000;
        response.region = response.town_id || response.county_id;
        if (response.disabled === -1) {
          response.disabled = true;
        } else {
          response.disabled = false;
        }
        this.editForm = response;
        response.nickname = unescape(response.nickname).replace(/&nbsp;/g, '');
        if (response.province_id) {
          this.defaultRegion = [
            response.province_id,
            response.city_id,
            response.county_id || -1,
            response.town_id || -1,
          ];
        }
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.container {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 0;
  overflow-x: hidden;

  .contant {
    width: 100%;
  }
}

.el-input {
  width: 280px;
}

.el-textarea {
  width: 280px;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}

::v-deep .el-form-item__error {
  min-width: 240px;
}

::v-deep .el-tabs__content {
  overflow: initial;
}

::v-deep .el-tab-pane .el-button.save {
  margin-left: 50%;
}
</style>
