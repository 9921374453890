<template>
  <div class="customer-list-order-container">
    <en-table-layout :toolbar="false" :tableData="tableData.data" :loading="loading">
      <template slot="table-columns">
        <el-table-column prop="sn" label="订单编号" />
        <el-table-column label="活动名称" width="280" class-name="active_col">
          <template slot-scope="scope">
            <img class="active_img" :src="scope.row.img" />
            <div>{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_status" label="订单状态">
          <template slot-scope="scope">
            <div>{{ getOrderStatusText(scope.row.order_status) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_status" label="支付状态">
          <template slot-scope="scope">
            <div>{{ getPaymentStatusText(scope.row.pay_status) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :formatter="MixinUnixToDate" label="下单时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleChuxingLook(scope.row)">查看出行人</el-button>
            <el-button type="text" @click="handleLianluoLook(scope.row)">查看联络人</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!-- 出行人信息 -->
    <el-dialog title="出行人信息" :visible.sync="dialogVisibleChuxing" width="650px">
      <el-tabs type="border-card">
        <el-tab-pane v-for="(item, index) in ChuxingList" :key="item.id" :label="'出行人' + (index + 1)">
          <el-form label-width="250px">
            <el-form-item label="出行人姓名">
              <span>{{ item.chinese_name }}</span>
            </el-form-item>
            <!-- <el-form-item label="出行人英文姓">
              <span>{{ item.eng_first_name }}</span>
            </el-form-item>
            <el-form-item label="出行人英文名">
              <span>{{ item.eng_second_name }}</span>
            </el-form-item>
            <el-form-item label="出行人证件类型">
              <span>{{ item.doc_type===1?'身份证':item.doc_type===2?'护照':item.doc_type===3?'港澳台通行证':'其他' }}</span>
            </el-form-item>
            <el-form-item label="出行人证件号">
              <span>{{ item.doc_nums }}</span>
            </el-form-item>
            <el-form-item label="出行人证件有效期至">
              <span>{{ item.doc_validity?item.doc_validity:'终身有效' }}</span>
            </el-form-item>
            <el-form-item label="出行人国籍">
              <span>{{ item.nationality===1?'中国':'其他' }}</span>
            </el-form-item> -->
            <el-form-item label="出行人手机号">
              <span>{{ item.phone }}</span>
            </el-form-item>
            <!-- <el-form-item label="出行人类型">
              <span>{{ item.identity_type === 1 ? "成人" : `儿童(${item.child_age})` }}</span>
            </el-form-item> -->
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 联络人信息 -->
    <el-dialog title="联络人信息" :visible.sync="dialogVisibleLianluo" width="650px">
      <el-form label-width="250px">
        <el-form-item label="联络人姓名">
          <span>{{ LianluoList.name }}</span>
        </el-form-item>
        <el-form-item label="联络人手机号">
          <span>{{ LianluoList.phone }}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Order from "@/api/order";

export default {
  name: "customerListOrder",
  props: ["member-id"],
  data () {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        member_id: this.memberId,
      },
      // 列表数据
      tableData: "",
      // 出行人信息模态框
      dialogVisibleChuxing: false,
      // 出行人信息
      ChuxingList: [],
      // 联络人信息模态框
      dialogVisibleLianluo: false,
      // 联络人信息
      LianluoList: {},
    };
  },
  mounted () {
    this.GET_MemberListOrder();
  },
  watch: {
    memberId () {
      this.params.member_id = this.memberId;
      this.GET_MemberListOrder();
    },
  },
  methods: {
    getOrderStatus (data) {
      if (data) {
        switch (data.order_status) {
          case "CONFIRM":
            return '待付款';
          case "PAID_OFF":
            return '待参加';
          case "IN":
            return '进行中';
          case "COMPLETE":
            return '已完成';
          case "REFUNDING":
            return '待退款';
          case "REFUNDED":
            return '已退款';
          case "CANCELLED":
            return '已取消';
        }
      }
      return data ? `Error(${data.order_status})` : 'Error';
    },
    /** 点击查看出行人按钮后 */
    handleChuxingLook (row) {
      API_Order.getOrderTAndE({}, row.order_id).then((res) => {
        this.ChuxingList = res.travelers ? res.travelers : {};
        this.dialogVisibleChuxing = true;
      });
    },
    /** 点击查看联络人按钮后 */
    handleLianluoLook (row) {
      API_Order.getOrderTAndE({}, row.order_id).then((res) => {
        console.log(res);
        this.LianluoList = res.emergenciers[0] ? res.emergenciers[0] : {};
        this.dialogVisibleLianluo = true;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_MemberListOrder();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_MemberListOrder();
    },

    /** 获取会员订单列表 */
    GET_MemberListOrder () {
      this.loading = true;
      const { page_no, page_size, member_id } = this.params;
      API_Order.getOrdersMember(this.params, member_id)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.customer-list-order-container {
  padding: 0;
  background: none;
}

::v-deep .active_col .cell {
  display: flex !important;
  align-items: center !important;
}

.active_col div {
  text-align: left;
  margin-left: 5px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.active_img {
  width: 50px;
  height: 50px;
}
</style>
