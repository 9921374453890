<template>
  <div class="customer-list-contact-container">
    <el-tabs type="border-card">
      <el-tab-pane v-for="(item,index) in tableData" :key="item.id" :label="'联络人'+(index+1)">
        <el-form label-width="350px">
          <el-form-item label="联络人姓名">
            <span>{{ item.name }}</span>
          </el-form-item>
          <el-form-item label="联络人手机号">
            <span>{{ item.phone }}</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
// import { Foundation } from "@/libs/common/utils";

export default {
  name: "customerListContact",
  props: ["member-id"],
  data() {
    return {
      // 列表loading状态
      loading: true,
      // 列表参数
      params: {
        member_id: this.memberId,
      },
      tableData: [],
    };
  },
  mounted() {
    this.GET_EmergencierList();
  },
  watch: {
    memberId() {
      this.params.member_id = this.memberId;
      this.GET_EmergencierList();
    },
  },
  methods: {
    /** 获取会员咨询列表 */
    GET_EmergencierList() {
      this.loading = true;
      API_Member.getEmergencierlist(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" type="text/scss" scoped>
.customer-list-contact-container {
  border-radius: 0;
}

.review-ask-content {
  word-break: break-all;
  color: #409eff;
}

.review-reply-content {
  color: #ff5722;
  word-break: break-all;
}
</style>
